<template>
  <div class="index">
    <div class="big" v-if="flag">
      <p class="btn">
        <button
          @click="atyOption = 0"
          class="button"
          :class="atyOption == 0 ? 'action' : ''"
        >
          最新活动
        </button>
        <button
          @click="atyOption = 1"
          class="button"
          :class="atyOption == 1 ? 'action' : ''"
        >
          我的活动
        </button>
      </p>
      <p class="title" v-if="newestacty.ongoing.length > 0">进行中</p>
      <div class="mask" v-if="newestacty.ongoing.length > 0">
        <ul v-for="item in newestacty.ongoing" :key="item.id">
          <li class="image">
            <img @click="signup(item)" :src="item.bannerRectangle" alt="" />
          </li>
          <li class="title">
            <p @click="signup(item)" class="title-head">
              {{ item.title }}
            </p>
            <p class="title-neck">
              {{ item.desp }}
            </p>
            <p class="title-time">
             <img  style="width:10px; height:10px;" src="../assets/timer.png" alt="">
             <span
                >{{ timefil(item.signStartTime) }}-{{
                  timefil(item.signEndTime)
                }}</span
              >
            </p>
            <div class="title-foot">
              <button v-if="atyOption == 1" @click="see(item)">查看</button>
              <button v-if="atyOption == 0" @click="signup(item)">{{item.status===1?'即将开始':'报名'}}</button>
              <div class="right">
                已有<span>{{ item.checkNum }}</span
                >人浏览
              </div>
            </div>
          </li>
        </ul>
        <button class="butload" @click="but1" v-if="butloading">
          加载更多
        </button>
      </div>
      <p
        class="title"
        v-if="newestacty.ended.length > 0"
        style="margin-top: 60px"
      >
        已结束
      </p>
      <div class="mask" v-if="newestacty.ended.length > 0">
        <ul v-for="item in newestacty.ended" :key="item.id">
          <li class="image">
            <img  @click="see(item)" :src="item.bannerRectangle" alt="" />
          </li>
          <li class="title">
            <p  @click="see(item)" class="title-head">
              {{ item.title }}
            </p>
            <p class="title-neck">
              {{ item.desp }}
            </p>
            <p class="title-time">
              <img style="width:10px; height:10px;" src="../assets/timer.png" alt="">
              <span
                >{{ timefil(item.signStartTime) }}-{{
                  timefil(item.signEndTime)
                }}</span
              >
            </p>
            <div class="title-foot">
              <button class="title-foot-but" @click="see(item)">查看</button>
              <div class="right">
                已有<span>{{ item.checkNum }}</span
                >人浏览
              </div>
            </div>
          </li>
        </ul>
        <button class="butload" @click="but2" v-if="butloadend">
          加载更多
        </button>
      </div>
    </div>
    <div class="big" v-else>
      <p class="btn">
        <button
          @click="atyOption = 0"
          class="button"
          :class="atyOption == 0 ? 'action' : ''"
        >
          最新活动
        </button>
        <button
          @click="atyOption = 1"
          class="button"
          :class="atyOption == 1 ? 'action' : ''"
        >
          我的活动
        </button>
      </p>
      <div class="center">
        <div class="content">
          <img src="../assets/Vector.png" alt="" />
          <p v-if="atyOption==0"  >暂无活动，精彩值得等待</p>
          <p v-else style="width:200px" >暂未报名任何活动</p>
          <button v-if="atyOption==1" @click="go">查看最近活动</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { activity_list, activity_mylist, usermsg, } from '../api/index'
import moment from 'moment'
export default {
  created () {
    if (this.$store.state.user != null) {
      //   获取个人信息
      usermsg().then(res => {
        this.usermsg = res.data;
      })
    }

    // 最新活动列表
    this.fnactivitying()
    this.fnactivityend()

  },
  data () {
    return {
      // 个人信息
      usermsg: {},
      atyOption: 0,
      flag: false,
      // 最新活动
      newestacty: {
        ongoing: [],
        ended: []
      },
      params1: {
        pageNum: 1,
        pageSize: 4,
        type: 1,
      },
      //已结束
      params2: {
        pageNum: 1,
        pageSize: 4,
        type: 2,
      },
      // 我参加的列表进行中
      params3: {
        pageNum: 1,
        pageSize: 4,
        type: 1,
      },
      // 我参加的列表已结束
      params4: {
        pageNum: 1,
        pageSize: 4,
        type: 2,
      },
      // 进行中加载按钮
      butloading: false,
      //已结束加载按钮
      butloadend: false,
    };
  },
  methods: {
    timefil (val) {
      return moment(val).format('YYYY.MM.DD')
    },
    go () {
      this.atyOption = 0
      this.flag = true;
    },
    //最新活动
    fnactivitying () {
      // 进行中
      let p = this.params1
      activity_list(p).then(res => {
          if(res.data.list.length>0){
              this.flag=true;
          }
        // if(res.data.list.length==0){
        //     this.flag = false;
        // }else{
        //   this.flag = true;
        // }
        this.newestacty.ongoing = res.data.list
        if (res.data.total > this.params1.pageSize) {
          this.butloading = true;
        } else {
          this.butloading = false;
        }
      }
      )
    },
    fnactivityend () {
      // 已结束
      let p = this.params2
      activity_list(p).then(res => {
        this.newestacty.ended = res.data.list
        if(res.data.list.length>0){
              this.flag=true;
          }
        // if(res.data.list.length==0){
        //     this.flag = false;
        // }else{
        //   this.flag = true;
        // }
        if (res.data.total > this.params2.pageSize) {
          this.butloadend = true;
        } else {
          this.butloadend = false;
        }

      }
      )
    },
    // 我的活动
    fnactivitymying () {
      // 进行中
      let p = this.params3;
      activity_mylist(p).then(res => {
        this.newestacty.ongoing = res.data.list
        if(res.data.list.length >0) {
            this.flag=true;
        }
        // if (this.newestacty.ended.length == 0 && this.newestacty.ongoing.length == 0) {
        //   this.flag = false;
        // }else{
        //      this.flag = true;
        // }
        if (res.data.total > this.params3.pageSize) {
          this.butloading = true;
        } else {
          this.butloading = false;
        }
      })
    },
    fnactivitymyend () {
      // 已结束
      let p = this.params4;
      activity_mylist(p).then(res => {
        this.newestacty.ended = res.data.list
        // 判断活动是否有
        // if (this.newestacty.ended.length == 0 && this.newestacty.ongoing.length == 0) {
        //   this.flag = false;
        // }else{
        //      this.flag = true;
        // }
        if(res.data.list.length >0) {
            this.flag=true;
        }
        if (res.data.total > this.params4.pageSize) {
          this.butloadend = true;
        } else {
          this.butloadend = false;
        }
      })
    },
    //获取活动详情
    details (id) {
      localStorage.removeItem('songdetails')
      window.open('/#/detailssing/' + id)

    },
    // 报名
    signup (val) {
      if (this.$store.state.user == null) {
        this.details(val.id)
        return
      }

      if (this.usermsg.webLoginType == 0 && this.usermsg.verifiedStatus == 3) {
        this.details(val.id)
        return;
      }
      if (this.usermsg.webLoginType == 1 && this.usermsg.userCompanyVo.status == 1) {
        this.details(val.id)
        return;
      }
      this.$popup({
        msg: '请先进行认证或认证成功后才能报名',
        type: 'warning'
      });
    },
    // 查看
    see (val) {
      if (this.$store.state.user == null) {
      this.centerDialogVisible=true;
       this.$popup({
        msg: '请先进行认证或认证成功后才能报名',
        type: 'warning'
      });
        return
      }
      this.details(val.id)
    },
    // but1活动经行中
    but1 () {
      if (this.atyOption == 1) {
        this.params3.pageSize += 4
        this.fnactivitymying()
      } else {
        this.params1.pageSize += 4
        this.fnactivitying()
      }
    },
    but2 () {
      if (this.atyOption == 1) {
        this.params4.pageSize += 4
        this.fnactivitymyend()
      } else {
        this.params2.pageSize += 4
        this.fnactivityend()
      }

    },
  },
  watch: {
    'atyOption': function (newval) {
      if (newval == 1) {
        if (this.$store.state.user == null) {
          this.$popup({msg:'请登录后再查看我的活动',type:'warning'})
         this.atyOption=0
         setTimeout(()=>{this.$router.replace('/')},100)
          return
        }
        this.params4.pageSize = 4
        this.params3.pageSize = 4
        //我的活动
        this.fnactivitymying()
        this.fnactivitymyend()
      } else {
        this.params1.pageSize = 4
        this.params2.pageSize = 4
        // 最新活动列表
        this.fnactivitying()
        this.fnactivityend()
      }

    },
    newestacty:{
        deep:true,
        handler: function(val){
          if(val.ended.length==0&&val.ongoing.length==0){
              this.flag = false;
          }
        }
    }

  },
}
</script>
<style scoped lang="scss" >
@import url("../styles/initialization.scss");
.index {
  background: #000000;
  width: 100%;
  padding-bottom: 80px;
  .big {
    min-height: 790px;
    > .center {
      width: 100%;
      height: 100vh;
      .content {
        // border: 1px solid red;
        width: 160px;
        margin: 200px auto;
        text-align: center;
        img {
          width: 90px;
          height: 90px;
        }
        p {
          margin-top: 30px;
          font-size: 14px;
          font-family: PingFang SC, PingFang SC-Regular;
          font-weight: 400;
          color: #666666;
        }
        button {
          cursor: pointer;
          border: none;
          margin-top: 12px;
          width: 112px;
          height: 32px;
          color: #ffffff;
          background: #ff004d;
          border-radius: 2px;
        }
      }
    }
    width: 1200px;
    margin: 0 auto;
    overflow: hidden;
    .btn {
      margin-top: 66px;
      margin-bottom: 54px;
      .button {
        cursor: pointer;
        border-radius: 2px;
        border: none;
        width: 101px;
        height: 38px;
        margin-right: 28px;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        text-align: center;
        background: #222222;
        color: #999999;
      }
      .action {
        background: #ffffff;
        color: #333333;
      }
    }
    .title {
      font-size: 20px;
      font-family: PingFang SC, PingFang SC-Medium;
      font-weight: 500;
      text-align: LEFT;
      color: #ffffff;
      margin-bottom: 24px;
    }
    .mask {
      overflow: hidden;
      padding-top: 46px;
      box-sizing: border-box;
      padding-left: 60px;
      background: rgba(196, 196, 196, 0.1);
      border-radius: 6px;
      //   height: 300px;
      ul {
        margin-bottom: 50px;
        display: flex;
        .image {
          width: 343px;
          height: 138px;
          img {
            width: 100%;
            cursor: pointer;
            height: 100%;
            border-radius: 6px;
          }
          //   border: 1px solid red;
          margin-right: 25px;
        }
        .title {
          width: 638px;
          margin-bottom: 0px;
          .title-head {
              cursor:pointer;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            font-size: 16px;
            font-family: PingFang SC, PingFang SC-Medium;
            font-weight: 500;
            text-align: LEFT;
            color: #ffffff;
          }
          .title-neck {
            font-size: 14px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #999999;
            margin-top: 8px;
          }
          .title-time {
            margin-top: 22px;
            font-size: 10px;
            font-family: PingFang SC, PingFang SC-Regular;
            font-weight: 400;
            text-align: LEFT;
            color: #555555;
            span {
              margin-left: 7px;
            }
          }
          .title-foot {
            margin-top: 22px;
            button {
              cursor: pointer;
              width: 93px;
              height: 31px;
              background: #ff004d;
              border: none;
              font-size: 14px;
              border-radius: 2px;
              font-family:PingFang SC-Semibold;
              font-weight: 600;
              color: #ffffff;
            }
            .title-foot-but {
              border: 1px solid #444444;
              background-color: rgba(68, 68, 68, 0);
            }
            .right {
              display: inline-block;
              margin-left: 15px;
              font-size: 12px;
              font-family: PingFang SC, PingFang SC-Regular;
              font-weight: 400;
              text-align: LEFT;
              color: #ffffff;
              span {
                color: #ff004d;
              }
            }
          }
        }
      }
      .butload {
        cursor: pointer;
        margin-bottom: 54px;
        border: none;
        width: 1080px;
        height: 60px;
        background: #202020;
        font-size: 14px;
        font-family: PingFang SC, PingFang SC-Regular;
        font-weight: 400;
        color: #eeeeee;
        border-radius: 4px;
      }
    }
  }
}

</style>
